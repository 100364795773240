import React, { Suspense, useContext, useEffect } from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import SignUp from "./pages/Auth/SignUp";
import SignIn from "./pages/Auth/SignIn";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Otp from "./pages/Auth/OtpIndex";
import Header from "./components/layout/Header";
import HomeReviewOrder from "./pages/Home/HomeReviewOrder";
import Home from "./pages/Home/Home";
import HomeNew from "./pages/Home/HomeNew";
import ResourcesIndex from "./pages/Resources_Details/ResourcesIndex";
import Market from "./pages/Market/Market";
import GoldReview from "./pages/Market/Market_Gold_Review";
import Portfolio from "./pages/Portfolio/Index";
import TransferHome from "./pages/TransferFunds/Index";
import Account from "./pages/Account/Index";
import Security from "./pages/Account/Security";
import PeymentMethod from "./pages/Account/PeymentMethod";
import CashBalance from "./pages/Account/CashBalance";
import Transactions from "./pages/Account/Transactions";
import Verification from "./pages/Account/Verification";
import AutoInvest from "./pages/Account/AutoInvest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import "../src/assets/styles/main.css";
// import "../src/assets/styles/responsive.css";
import InvoiceIndex from "./pages/Account/InvoiceIndex";
import Notifications from "./pages/Account/Notifications";
import InviteFriends from "./pages/Account/InviteFriends";
import Faq from "./pages/Faq/FaqIndex";
import CookiesPolicy from "./pages/CookiesPolicy/CookiesIndex";
import Terms from "./pages/Terms_And_Condition/TermsIndex";
import Review from "./pages/Review/ReviewIndex";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import News from "./pages/News";
import PrivateRoute from "./components/PrivateRoute";
import ContactUsIndex from "./pages/contact_us/ContactUs";
import PricingIndex from "./pages/Pricing/PricingIndex";
import Cokie from "./pages/Cokies";
import HowWorks from "./pages/HowWorks";
import Accessibility from "./pages/Accessibility";
import About from "./pages/About";
import NotificationsIndex from "./pages/Notifications/NotificationsIndex";
import Price from "./pages/UsPrice/Index";
import BuyConfirm from "./components/order/BuyConfirm";
import EmailVerify from "./pages/EmailVerify";
import FeesIndex from "./pages/StorageFees/FeesIndex";
import EmailSent from "./pages/EmailSent";
import ScrollToTop from "./components/ScrollToTop";
import GoldPage from "./pages/Market/GoldPage";
import SilverPage from "./pages/Market/SilverPage";
import AutoInvestForm from "./pages/Account/AutoInvestForm";
import TransactionDetails from "./pages/Market/Gold/TransactionDetails";
import Deposite from "./pages/Account/Deposite";
import TransactionView from "./pages/Account/TransactionView";
import AccountMenu from "./pages/Account/AccountMenu";
import NewPassword from "./pages/Auth/NewPassword";
import LegalNotice from "./pages/LegalNotice/index";
import CreditCardForm from "./components/CreditPaymentForm";
import AgreementPolicies from "./pages/Account/AgreementPolicies";
import SupportCenter from "./pages/Account/SupportCenter";
import GoldInfusion from "./pages/GoldInfusion";
import SilverInfusion from "./pages/SilverInfusion";

function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { pathname } = useLocation();

  // useEffect(() => {
  //   // Function to add Zendesk script
  //   const addZendeskScript = () =>
  //     {
  //     const existingIframe=document.getElementById('launcher');
  //     if (existingIframe) {
  //       existingIframe.style.display="block";
  //     }
  //   };

  //   // Function to remove Zendesk script
  //   const removeZendeskScript = () => {
  //     const existingIframe=document.getElementById('launcher');
  //     if (existingIframe) {
  //       existingIframe.style.display="none";
  //     }
  //   };
  //   if (pathname==='/gold-chart/light'||pathname==='/gold-chart/dark'||pathname==='/sliver-chart/light'||pathname==='/sliver-chart/dark'||pathname==='/gold'||pathname==="/silver") {
  //     removeZendeskScript();
  //   } else {
  //     addZendeskScript();
  //   }
  //   return () => {
  //     removeZendeskScript();
  //   };
  // }, [pathname]);

  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/email-sent" element={<EmailSent />} />

      <Route path="/" element={<Home />} />
      <Route path="/credit-card-payment" element={<CreditCardForm />} />
      {/* <Route path="/homerevieworder" element={<HomeReviewOrder />} /> */}
      {/* <Route path="/homenew" element={<HomeNew />} /> */}
      <Route path="/:id/resource" element={<ResourcesIndex />} />
      <Route path="/:id/news" element={<News />} />

      <Route path="/market" element={<Market />} />
      <Route path="/gold" element={<GoldPage />} />
      <Route path="/silver" element={<SilverPage />} />

      <Route path="/market-gold" element={<GoldReview />} />

      <Route path="/transferHome" element={<TransferHome />} />
      <Route path="/Faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="/cookies-policy" element={<CookiesPolicy />} />

      <Route path="/terms" element={<Terms />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<ContactUsIndex />} />
      <Route path="/pricing" element={<PricingIndex />} />
      <Route path="/cookie-policy" element={<Cokie />} />
      <Route path="/how-it-works" element={<HowWorks />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/review" element={<Review />} />
      <Route path="/notifications-home" element={<NotificationsIndex />} />
      <Route path="/Price" element={<Price />} />
      <Route path="/:id/:type/order/complete" element={<BuyConfirm />} />
      <Route path="/storage-fees" element={<FeesIndex />} />

      <Route path="/email-verify" element={<EmailVerify />} />
      <Route path="/legal-notice" element={<LegalNotice />} />

      <Route path="/gold-chart/:theme" element={<GoldInfusion />} />
      <Route path="/silver-chart/:theme" element={<SilverInfusion />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/:id/:type/order/complete" element={<BuyConfirm />} />
        <Route path="/account" element={<Account />} />
        <Route path="/security" element={<Security />} />
        <Route path="/PeymentMethod" element={<PeymentMethod />} />
        <Route path="/CashBalance" element={<CashBalance />} />
        <Route path="/CashBalance/Deposit" element={<Deposite />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transaction/:id" element={<TransactionView />} />
        <Route path="/Verification" element={<Verification />} />
        <Route path="/AutoInvest" element={<AutoInvest />} />
        <Route path="/AutoInvest/create" element={<AutoInvestForm />} />
        <Route path="/InvoiceIndex" element={<InvoiceIndex />} />
        <Route path="/Notifications" element={<NotificationsIndex />} />{" "}
        {/* Notifications */}
        <Route path="/InviteFriends" element={<InviteFriends />} />
        <Route path="/AccountMenu" element={<AccountMenu />} />
        <Route path="/agreement-menu" element={<AgreementPolicies />} />
        <Route path="/support-center" element={<SupportCenter />} />
        <Route path="/TransactionDetails" element={<TransactionDetails />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
export default App;
