import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tabs,
  Space,
  Typography,
} from "antd";
import {
  CreditCardOutlined,
  DownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

import { useNavigate, Link } from "react-router-dom";
import encryptDecrypt from "../../helper/encryptDecrypt";
import useDebounce from "../../hooks/useDebounce";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import RightAngle from "../../assets/images/icon/angle-right.svg";
import Sellcart from "../../assets/images/sell-cart.svg";
import WalletIcon from "../../assets/images/wallet-outline.svg";
import VisaBtn from "../../assets/images/visa.png";

import Paypal from "../../assets/images/paypal.png";
import BankHome from "../../assets/images/bank-home.png";
import BankAcc from "../../assets/images/bank-account.png";

import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import TabPane from "antd/es/tabs/TabPane";
import moment from "moment";
import { BackArrow } from "./Index";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";

function CashBalance() {
  const { request } = useRequest();
  const { userProfile } = useContext(AuthContext);
  const { isMobile } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [withdraw, showWithdraw] = useState(false);
  const [list, setList] = useState([]);

  const getTransaction = (id) => {
    setLoading(true);
    request({
      url: `/common/wallet?userId=${id}`,
      method: "GET",
      onSuccess: (res) => {
        setLoading(false);
        const { data } = res;
        if (data.data.length) {
          setList(() => data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!userProfile) return;
    getTransaction(userProfile._id);
  }, [userProfile, refresh]);

  return (
    <>
      <section className="main">
        <Header />

        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">{lang("Cash Balance")}</div>
                <Card>
                  <div className="cash-header">
                    <div className="cash-left-bar">
                      <img src={WalletIcon} />
                      <div className="cash-inner-bar">
                        <span>{lang("Total Balance")}</span>
                        <h6>
                          {userProfile &&
                            userProfile.cashBalance.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                        </h6>
                      </div>
                    </div>
                    <div className="cash-right-bar">
                      <Button
                        className="visa-in-btn cash-right-btn"
                        type="button"
                        onClick={() => showWithdraw(true)}
                      >
                        {lang("Withdraw")}
                      </Button>
                      <Button
                        className="ant-btn-default cash-right-btn"
                        type="button"
                        onClick={() => navigate(`/CashBalance/Deposit`)}
                      >
                        {lang("Add")}
                      </Button>
                    </div>
                  </div>
                </Card>

                {/* <>
               <div className="pervious-headding">
                  Pervious Transactions <img src={RightAngle} />
                </div>
                <Card>
                  <div className="pervious-body">
                    <ul>
                      {
                        list.map((item, index) =>
                          <li key={index}>
                            <div className="pervious-left-bar">
                              <img src={Sellcart} />
                              <div className="pervious-inner-bar">
                                <h6>Sell Gold</h6>
                                <p>{moment(item.created_at).format('lll')} </p>
                              </div>
                            </div>
                            <div className="pervious-right-bar">
                              <h6>{item.amount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}</h6>
                              {item.balanceStatus == 'withdraw' ? <span>Withdraw </span> : <span style={{ color: '#65C953' }}>Deposit</span>}
                            </div>
                          </li>)
                      }

                    </ul>
                  </div>
                </Card>
               </> */}
              </div>
            </Col>
          </Row>
        </div>

        {withdraw && (
          <Withdraw
            show={withdraw}
            hide={() => showWithdraw(false)}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </section>
      <Footer />
    </>
  );
}

const Withdraw = ({ show, hide, refresh }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { setRefreshBalance } = useContext(AuthContext);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { cashBalance } = values;

    setLoading(true);

    request({
      url: "/app/auth/withdrawOrAdd ",
      method: "PUT",
      data: { balanceStatus: "withdraw", cashBalance },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({ cashBalance: null });
          refresh();
          setRefreshBalance((prev) => !prev);
          hide();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      className="modal01"
      onCancel={hide}
      title={lang("Withdraw Amount")}
      open={show}
      footer={false}
    >
      <div>
        <Form
          layout="vertical"
          className="row-col"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="username-form"
            label={lang("Amount")}
            name="cashBalance"
            rules={[
              { required: true, message: lang("Please enter the amount!") },
            ]}
          >
            <Input className="form-of-type" type="text" />
          </Form.Item>

          <div className="mt-3 mx-auto text-center">
            <Form.Item className="username-form">
              <Button loading={loading} htmlType="submit">
                {lang("Submit")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const Desposit = ({ show, hide, refresh }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { cashBalance } = values;

    setLoading(true);

    request({
      url: "/app/auth/withdrawOrAdd ",
      method: "PUT",
      data: { balanceStatus: "add", cashBalance },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({ cashBalance: null });
          if (refresh) refresh();
          ShowToast(data.message, Severty.SUCCESS);
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      className="modal01"
      onCancel={hide}
      title={lang("Add Amount")}
      open={show}
      footer={false}
    >
      <div>
        <Form
          layout="vertical"
          className="row-col"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="username-form"
            label={lang("Amount")}
            name="cashBalance"
            rules={[
              { required: true, message: lang("Please enter the amount!") },
            ]}
          >
            <Input className="form-of-type" type="text" />
          </Form.Item>

          <div className="mt-3 mx-auto text-center">
            <Form.Item className="username-form">
              <Button loading={loading} htmlType="submit">
                {lang("Submit")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CashBalance;
