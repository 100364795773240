import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Tooltip,
  Select,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";

import { InfoCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link, useNavigate } from "react-router-dom";
import Righterr from "../../assets/images/right-errow.png";
import CurrencyImg from "../../assets/images/usd.svg";

import Cash from "../../assets/images/icon/cash.svg";
import Curncy from "../../assets/images/icon/curncy.svg";
import Bank from "../../assets/images/icon/bank.svg";
import Mynt from "../../assets/images/icon/mynt.svg";
import Credit from "../../assets/images/icon/credit.svg";
import Paypal from "../../assets/images/icon/paypal.svg";
import Visa from "../../assets/images/visa.png";
import BankAcc from "../../assets/images/icon/bank-acc.svg";
import Bitcoin from "../../assets/images/icon/bitcoin.svg";
import BankCheck from "../../assets/images/icon/bank-check.svg";
import Bankwire from "../../assets/images/icon/bank-wire.svg";

import Gold from "../../assets/images/gold_circle_.svg";
import Silver from "../../assets/images/silver_circle_.svg";

import { currencyList } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import PlaidLink from "../../pages/Account/PlaidLink";
import CreditCardForm from "../CreditPaymentForm";
import lang from "../../helper/langHelper";
import PlaidModal from "../../pages/Account/PlaidLink";
import { formatNumber } from "../../helper/functions";
const { Panel } = Collapse;

const Buy = ({}) => {
  const [loading, setLoading] = useState(false);

  const {
    prices,
    goldRates,
    silverRates,
    serviceFee,
    setRefreshBalance,
    silverPrice,
    goldPrice,
  } = useAppContext();
  const { isLoggedIn, userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();

  const [amount, setAmount] = useState();
  const [networkFee, setNetworkFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [quantity, setQuantity] = useState();
  const [categoryId, setCategoryId] = useState();
  const [category, setCategory] = useState();
  const [paymentMethod, setPayment] = useState("cashBalance");
  const [isGold, setIsGold] = useState(false);
  const [currency, setCurrency] = useState(currencyList[0]);
  const [accounts, setAccounts] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const { language } = useAppContext();

  useEffect(() => {
    if (!prices.length) return;

    setCategory(prices[0]);
    setCategoryId(prices[0]._id);
  }, [prices]);

  useEffect(() => {
    if (!category) return;
    category.atomicSymbol == "XAU" ? setIsGold(true) : setIsGold(false);
  }, [category]);

  const handleOptionSelect = (value) => {
    setPayment(value);
    if (value === "credit/debit card") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  };

  const onFinish = (values) => {
    if (!isLoggedIn) return navigate("/login");
    // const { categoryId, frequency, amount } = values
    // Handle form submission logic here

    const payload = {
      typeOfTransaction: "Buy",
      categoryId,
      amount,
      payment_method: paymentMethod,
      currency,
      quantity,
    };

    setLoading(true);
    request({
      url: `/app/transaction/createOrder`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        const { data } = res;
        if (res.statusText == "Success") {
          const { quantity, payment_method, amount, _id: orderId } = data;

          navigate(`/${orderId}/Buy/order/complete`);
          setAmount(0);
          setQuantity(0);
        } else {
          setLoading(false);
          ShowToast(lang("Buy successfully"), Severty.SUCCESS);
        }
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  useEffect(() => {
    if (!goldRates || !category || !serviceFee) return;

    let metal_amount = localStorage.getItem("metal_amount");

    if (!metal_amount || isNaN(Number(metal_amount))) {
      metal_amount = 700;
    } else {
      metal_amount = Number(metal_amount);
    }

    amountChange(metal_amount);
  }, [goldRates, category, serviceFee]);

  const transaction = ({ orderId, quantity, amount, payment_method }) => {
    const payload = {
      orderId,
      quantity,
      amount,
      payment_method,
    };
    setLoading(true);
    request({
      url: `/app/transaction/transaction`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        form.setFieldsValue({ categoryId: "", frequency: "", amount: "" });
        // setData(data.data);
        ShowToast(lang("Buy successfully"), Severty.SUCCESS);
        setRefreshBalance((prev) => !prev);
        setAmount();
        setQuantity();
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [metalSelector, showMetalSelector] = useState(false);
  const [show, setShow] = useState(false);

  const handleCurrencyButtonClick = () => {
    setShowCurrencySelector(!showCurrencySelector);
  };

  const amountChange = (amount) => {
    localStorage.setItem("metal_amount", amount);
    setAmount(amount);
    if (!goldRates || !category || !serviceFee) return;
    let processing = 0;
    if (paymentMethod == "credit/debit card") {
      processing = serviceFee.credit_charges;
    } else if (paymentMethod == "paypal") {
      processing = serviceFee.paypal_charges;
    } else if (paymentMethod == "bitcoin") {
      processing = serviceFee.bitcoin_charges;
    } else {
      processing = 0;
    }

    processing = (processing / 100) * amount;
    setProcessingFee(processing);
    const purchaseAmount = amount - processing;
    if (category.atomicSymbol == "XAU") {
      const quantity = purchaseAmount / goldRates;
      setQuantity(parseFloat(quantity.toFixed(4)));
    } else if (category.atomicSymbol == "XAG") {
      const quantity = purchaseAmount / silverRates;
      setQuantity(parseFloat(quantity.toFixed(4)));
    }
  };

  const quantityChange = (quantity) => {
    setQuantity(parseFloat(quantity?.toFixed(4)));
    if (!goldRates || !category || !serviceFee) return;

    if (category.atomicSymbol == "XAU") {
      const sellAmount = quantity * goldRates;
      const network = (serviceFee.paymentGatewayCharges / 100) * sellAmount;
      const processing =
        (serviceFee.percentageOnTransaction / 100) * sellAmount;
      const amount = sellAmount;
      setAmount(parseFloat(amount.toFixed(2)));

      setNetworkFee(network);
      setProcessingFee(processing);
    } else if (category.atomicSymbol == "XAG") {
      const sellAmount = quantity * silverRates;
      const processing =
        (serviceFee.percentageOnTransaction / 100) * sellAmount;
      const amount = sellAmount; // + processing; // + network;
      setAmount(parseFloat(amount.toFixed(2)));
      setProcessingFee(processing);
    }
  };

  const [isVisaDropdownOpen, setIsVisaDropdownOpen] = useState(false);

  const getBalance = () => {
    request({
      url: `/app/payment/get-balance`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        setAccounts(data);
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };
  const getPlaidAccount = () => {
    request({
      url: `/app/payment/get-accounts`,
      method: "POST",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        setAccountList(data.response); // Set accountList to the 'response' array inside 'data'
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    let token = localStorage.getItem("userProfile")
      ? JSON.parse(localStorage.getItem("userProfile")).accessToken
      : false;
    setShowToken(token);
    getBalance();
    // getPlaidAccount();
  }, [isLoggedIn]);

  const dropdownRender = (menu) => (
    <div className="custom-dropdown">
      <ul className="buy-now-list-container">{menu}</ul>
    </div>
  );

  useEffect(() => {
    amountChange(amount);
  }, [paymentMethod, showForm]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="row-col mt-3"
      >
        {showCurrencySelector && (
          <CurrencyDropDown
            value={currency}
            show={showCurrencySelector}
            hide={() => setShowCurrencySelector(false)}
            onSelect={(data) => {
              setCurrency(data);
            }}
          />
        )}

        {metalSelector && (
          <MetalDropDown
            value={categoryId}
            show={metalSelector}
            hide={() => showMetalSelector(false)}
            onSelect={(data) => {
              setCategory(data);
              setCategoryId(data._id);
            }}
          />
        )}
        <Form.Item
          className="username-form"
          label=""
          name="amount"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!amount) {
                  return Promise.reject(new Error(lang("Please enter amount")));
                }
                return Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (amount > 500000) {
                  return Promise.reject(
                    new Error(
                      lang(
                        "You can purchase up to $500,000.00 per transaction using this payment method."
                      )
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <div className="currency-selec-group">
            <div className="currency-main flex flex-col justify-center ml-auto">
              <span className="doller-sym">$</span>{" "}
            </div>
            <InputNumber
              value={formatNumber(amount)}
              //  max={500001}
              placeholder="0.00"
              className="ant-input ant-input-status-success ps-2 buy-sale"
              type="text"
              onChange={(value) => {
                // setAmount(amount);
                amountChange(value);
              }}
            />
          </div>
        </Form.Item>

        <Form.Item
          className="username-form"
          label=""
          name="categoryId"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!categoryId) {
                  return Promise.reject(new Error(lang("Please select metal")));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <div className="currency-selec-group">
            <InputNumber
              value={formatNumber(quantity)}
              min={0}
              placeholder="0.00"
              className="ant-input ant-input-status-success  buy-sale"
              // type="text"
              onChange={(value) => {
                quantityChange(value);
                //setQuantity(value);
              }}
            />
            <div className="currency-main d-flex  align-items-center ps-3  ml-auto">
              <span className="currency-teg">OZ</span>
              <Select
                className="buy-ctg"
                defaultValue={lang("Gold")}
                onChange={(id) => {
                  const find = prices.find((item) => item._id == id);
                  if (find) {
                    setCategory(find);
                    setCategoryId(find._id);
                  }
                }}
              >
                {prices.map((item) => (
                  <Select.Option
                    value={item._id}
                    label={
                      language == "en" ? item.categories : item.ar_categories
                    }
                  >
                    {language == "en" ? item.categories : item.ar_categories}
                  </Select.Option>
                ))}

                {/* <Select.Option  value="silver">Silver</Select.Option> */}
              </Select>
            </div>
          </div>
        </Form.Item>

        <div className="position-relative">
          <Select
            className="buy-select"
            defaultValue={"cashBalance"}
            dropdownRender={dropdownRender}
          >
            <Select.Option value={"cashBalance"}>
              {" "}
              <li
                className="buy-now-list"
                onClick={() => {
                  setPayment("cashBalance");
                  // setIsVisaDropdownOpen(false);
                  // setShowModal(true);
                }}
              >
                <Link>
                  <img src={Cash} />
                  <div className="dropdawn-visa-text">
                    <h6>
                      {lang("Cash Balance")}:{" "}
                      <span>
                        ${" "}
                        {userProfile
                          ? formatNumber(userProfile?.cashBalance?.toFixed(2))
                          : `0.00`}
                      </span>
                    </h6>
                    {/* <p>$0.01 minimum</p> */}
                  </div>
                </Link>
              </li>{" "}
            </Select.Option>
            <Select.Option
              value={"credit/debit card"}
              onChange={() => {
                handleOptionSelect("credit/debit card");
                // setShowModal(true);
              }}
            >
              {" "}
              <li
                className="buy-now-list"
                onClick={() => {
                  setPayment("credit/debit card");
                  // setShowForm(true);
                  handleOptionSelect("credit/debit card");
                }}
              >
                <Link>
                  <img src={Credit} />
                  <div className="dropdawn-visa-text">
                    <h6>{lang("Credit/Debit Card")}</h6>
                    <p>
                      {serviceFee?.credit_charges} % {lang("Service Fee")}
                    </p>
                  </div>
                </Link>
              </li>{" "}
              {showForm && <CreditCardForm onFinish={onFinish} />}{" "}
            </Select.Option>
            <Select.Option value={"paypal"}>
              <li
                className="buy-now-list"
                onClick={() => {
                  setShowForm(false);
                  setPayment("paypal");
                }}
              >
                <Link>
                  <img src={Paypal} />
                  <div className="dropdawn-visa-text">
                    <h6>PayPal</h6>
                    <p>
                      {serviceFee?.paypal_charges} % {lang("Service Fee")}
                    </p>
                  </div>
                </Link>
              </li>{" "}
            </Select.Option>

            <Select.Option value={"bankWire"}>
              {" "}
              <li
                className="buy-now-list"
                // onClick={()=> setShow(true)}
                onClick={() => setPayment("bankWire")}
              >
                <Link>
                  <img src={Bankwire} />
                  <div className="dropdawn-visa-text">
                    <h6>Bank Wire</h6>
                    <p>{lang("No Fee")}</p>
                  </div>
                </Link>
              </li>{" "}
            </Select.Option>

            <Select.Option value={"bank-account"}>
              <li
                className="buy-now-list"
                onClick={() => setPayment("bank-account")}
              >
                <Link>
                  <img src={BankAcc} />
                  <div className="dropdawn-visa-text">
                    <h6>{lang("Bank Account")}</h6>
                    <p>{lang("No Fee")}</p>
                  </div>
                </Link>
              </li>{" "}
            </Select.Option>
            <Select.Option value={"bitcoin"}>
              {" "}
              <li
                className="buy-now-list"
                onClick={() => setPayment("bitcoin")}
              >
                <Link>
                  <img src={Bitcoin} />
                  <div className="dropdawn-visa-text">
                    <h6>BitCoin</h6>
                    <p>
                      {serviceFee?.bitcoin_charges} % {lang("Service Fee")}
                    </p>
                  </div>
                </Link>
              </li>
            </Select.Option>
            <Select.Option value={"echeck"}>
              {" "}
              <li className="buy-now-list" onClick={() => setPayment("echeck")}>
                <Link>
                  <img src={BankCheck} />
                  <div className="dropdawn-visa-text">
                    <h6>{lang("Personal Check")}</h6>
                    <p>{lang("No Fee")}</p>
                  </div>
                </Link>
              </li>
            </Select.Option>
          </Select>
        </div>

        <div className="mb-3">
          {isNaN(amount) ? null : (
            <Collapse
              accordion
              className="collapse-main"
              // activeKey={`1`}
            >
              {[{ key: "1" }].map((item) => (
                <Panel
                  key={item.key}
                  header={
                    <div className="collaps-custom-header">
                      {lang("You get")}{" "}
                      <span className="bold-font">
                        {" "}
                        {quantity} OZ{" "}
                        {category && language === "en"
                          ? category.categories
                          : category.ar_categories}{" "}
                      </span>{" "}
                      {lang("for")}{" "}
                      <span className="bold-font">
                        {" "}
                        $ {formatNumber(amount)}
                      </span>
                    </div>
                  }
                >
                  <div className="collaps-body">
                    <div className="update-text">
                      <span>
                        {/* {quantity} oz {category && category.categories} */}
                        {quantity} OZ ({formatNumber(amount)} {"  "}
                        {category && category.categories} $/OZ)
                        {/* <span className="text-label-secondary">
                          {isGold && amount
                            ? goldRates
                            : silverRates && silverRates}
                        </span> */}
                        {/* <Tooltip title="prompt text" className="tooltip-bar">
                          <span>
                            <InfoCircleOutlined />
                          </span>
                        </Tooltip> */}
                      </span>
                      <p>
                        {/* $ {processingFee ?? 0} */}
                        {formatNumber(
                          amount - (processingFee ?? 0) - (networkFee ?? 0)
                        )}
                      </p>
                    </div>
                    <div className="update-text">
                      <span>
                        {lang("Processing Fee")}
                        <Tooltip
                          title={lang(
                            "This is charged by Mynt to cover costs associated with payment processing. This may vary based on the payment method you choose later"
                          )}
                          className="tooltip-bar"
                        >
                          <span>
                            <InfoCircleOutlined />
                          </span>
                        </Tooltip>
                      </span>
                      <p>$ {processingFee.toFixed(2)}</p>
                    </div>
                  </div>
                </Panel>
              ))}
            </Collapse>
          )}
        </div>
        <TimerCount count={10} onComplete={() => null} type={"Buy"} />
        <div className="  ">
          <Form.Item>
            <Button
              loading={loading}
              className="visa-in-btn"
              type="button"
              htmlType="submit"
            >
              {lang("Buy")} {category && category.categories}
              {/* <img src={Righterr} /> */}
            </Button>
          </Form.Item>
          {/* <Button className="re-visa-in-btn" type="button">
                    Review order
                </Button> */}
        </div>
      </Form>
      {paymentMethod == "bankWire" && (
        <PlaidLink onSelect={(data) => setAccounts(data)} />
      )}
      {paymentMethod === "credit/debit card" && <CreditCardForm />}
    </>
  );
};

const CurrencyDropDown = ({ hide, onSelect, value }) => {
  const [currencies, setCurrencies] = useState([...currencyList]);

  const onSearch = ({ target }) => {
    const filter = currencyList.filter((cur) =>
      cur.name.toLocaleLowerCase().includes(target.value.toLocaleLowerCase())
    );

    setCurrencies(filter);
  };

  return (
    <div className="currency-inset ">
      <div className="inset-header">
        <h1 className="">{lang("Select a currency")}</h1>
        <button className="headerclosebutton" type="button" onClick={hide}>
          <svg
            fill="none"
            height="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div className="mt-4">
        <div className="searchbar-menu">
          <div className="serch-iconbar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-label-tertiary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              ></path>
            </svg>
          </div>
          <Input
            className="currency-serch"
            placeholder={lang("Search")}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="currency-select-body">
        <ul>
          {currencies.map((item) => (
            <li key={item}>
              <Button
                className="currency-btn-body"
                type="button"
                onClick={() => {
                  onSelect(item);
                  hide();
                }}
              >
                <span className="currency-flag">
                  <img
                    className=""
                    width={25}
                    src="https://static.moonpay.com/widget/currencies/aud.svg"
                    alt="aud"
                  />
                </span>
                <div className="currency-names">
                  <span className="">
                    <h6 className="">{item.name}</h6>
                    <p className="">{item.fullName}</p>
                  </span>
                </div>
                <span className="right-btn-icon ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-testid="chevron-right-icon"
                    width="20"
                    height="20"
                    color="var(--label-tertiary)"
                    className="text-label-tertiary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MetalDropDown = ({ hide, onSelect, value }) => {
  const { prices } = useAppContext();
  const [metals, setMetals] = useState([...prices]);

  const onSearch = ({ target }) => {
    const filter = prices.filter((cur) =>
      cur.categories
        .toLocaleLowerCase()
        .includes(target.value.toLocaleLowerCase())
    );

    setMetals(filter);
  };

  return (
    <div className="currency-inset ">
      <div className="inset-header">
        <h1 className="">{lang("Select a metal")}</h1>
        <button className="headerclosebutton" type="button" onClick={hide}>
          <svg
            fill="none"
            height="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div className="mt-4">
        <div className="searchbar-menu">
          <div className="serch-iconbar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-label-tertiary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              ></path>
            </svg>
          </div>
          <Input
            className="currency-serch"
            placeholder={lang("Search")}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="currency-select-body">
        <ul>
          {metals.map((item) => (
            <li key={item}>
              <Button
                className="currency-btn-body"
                type="button"
                onClick={() => {
                  onSelect(item);
                  hide();
                }}
              >
                <span className="currency-flag">
                  <img
                    className=""
                    width={25}
                    src={item.atomicSymbol == "XAU" ? Gold : Silver}
                    alt="aud"
                  />
                </span>
                <div className="currency-names">
                  <span className="">
                    <h6 className="">{item.categories}</h6>
                    <p className="">{item.atomicSymbol}</p>
                  </span>
                </div>
                <span className="right-btn-icon ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-testid="chevron-right-icon"
                    width="20"
                    height="20"
                    color="var(--label-tertiary)"
                    className="text-label-tertiary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Buy;

export const TimerCount = ({ count, onComplete }) => {
  const [resendTimer, setResendTimer] = useState(count ?? 10);

  useEffect(() => {
    let timer;
    if (resendTimer >= 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    } else {
      setResendTimer(count);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  return (
    <div className="timer-clock">
      <ClockCircleOutlined />
      {lang("Quote updates in")} {resendTimer}s
    </div>
  );
};
