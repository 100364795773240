import React, { createContext, useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
import axios from "axios";
import apiPath from "../constants/api_urls";
import lang from "../helper/langHelper";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState({ token: null });
  const [userProfile, setUserProfile] = useState();
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [refreshBalance, setRefreshBalance] = useState(false);
  const [language, setLanguage] = useState("en");
  const [goldAccount, setGoldAccount] = useState({
    price: 0,
    quantity: 0,
  });

  useEffect(() => {
    const userLanguage = userProfile?.userLanguage;

    if (userLanguage) {
      localStorage.setItem("languageSet", userLanguage);
      setLanguage(userLanguage);
    }
  }, [userProfile]);

  const [silverAccount, setSilverAccount] = useState({
    price: 0,
    quantity: 0,
  });

  const fetchUser = async (user) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        apiPath.baseURL + `/${apiPath.profile}`,
        {
          headers,
        }
      );
      const result = response.data.data;

      if (result?.data?.length) {
        setUserProfile(result?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userProfile) return;

    if (!userProfile.metalBalance) return;

    userProfile.metalBalance.map((item) => {
      if (item.categoryId == "66069035fa639b23dcf4b3a6") {
        const price = item.quantity / item.currentPrice;
        setGoldAccount({
          price,
          quantity: item.quantity,
        });
      } else {
        const price = item.quantity / item.currentPrice;
        setSilverAccount({
          price,
          quantity: item.quantity,
        });
      }
    });
  }, [userProfile]);

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    theme = theme != undefined ? theme : "true";

    if (theme == "true") {
      import("../assets/styles/darkTheme.css");
      import("../assets/styles/responsive.css");
      setIsDarkTheme(true);
      return;
    }

    import("../assets/styles/main.css");
    import("../assets/styles/responsive.css");
    setIsDarkTheme(false);

    // setTimeout(() => setLoading(false), 200)
  }, [userProfile]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) return;

    let user = JSON.parse(localStorage.getItem("userProfile"));
    if (user) {
      fetchUser(user);
      //setUserProfile(user)
    }
    setIsLoggedIn(true);
    setSession({ token: token });
  }, [refreshBalance]);

  useEffect(() => {
    if (!userProfile) return;
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
  }, [userProfile]);

  useEffect(() => {
    if (!isLoggedIn) return;
    // Additional logic on login
  }, [isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);
    // alert("login");
    return <Navigate to="/home" />;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("languageSet", "en");
    localStorage.removeItem("theme", "true");
    setIsLoggedIn(false);
    setSession({ token: null });
    setUserProfile(null); // Set userProfile to null instead of undefined
    // ShowToast(lang("Logout Successfully"), Severty.SUCCESS);
    // window.location.assign('/login');
    return <Navigate to="/login" />;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        session,
        setSession,
        userProfile,
        setUserProfile,
        login,
        logout,
        goldAccount,
        silverAccount,
        isDarkTheme,
        refreshBalance,
        setRefreshBalance,
        language,
        setLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
