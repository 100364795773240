import { Row, Col, Button, Input } from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Nav,
  Navbar,
  Accordion,
} from "react-bootstrap";

import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import AboutImg from "../../assets/images/about-img.png";
function AboutIndex() {
  const { request } = useRequest();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/content/get-content/about-us`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        if (data.data.length) {
          setData(data.data[0]);
        }
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="tital transactions-tital ">
                  <h4 className="m-0">Legal Notice</h4>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Row gutter={45}>
                    <Col md={9}>
                      <div className="about-img">
                        <img src={AboutImg} />
                      </div>
                    </Col>
                    <Col md={15}>
                      <div className="about-mynt">
                        <h2 className="mh2-0">Terms of Service</h2>
                        <p>
                          Welcome to our website. By accessing or using our
                          site, you agree to comply with and be bound by these
                          terms and conditions.
                        </p>

                        <h2 className="mh2-0">Privacy Policy</h2>
                        <p>
                          We respect your privacy. Our privacy policy explains
                          how we collect, use, and protect your personal
                          information.
                        </p>

                        <h2 className="mh2-0">Cookie Policy</h2>
                        <p>
                          We use cookies to enhance your experience. By
                          continuing to visit this site, you agree to our use of
                          cookies.
                        </p>

                        <h2 className="mh2-0">Disclaimer</h2>
                        <p>
                          All information on this site is provided "as is"
                          without any warranties, express or implied. We are not
                          liable for any errors or omissions.
                        </p>

                        <h2 className="mh2-0">Copyright Notice</h2>
                        <p>
                          © 2024 Mynt. All rights reserved. Unauthorized use
                          and/or duplication of this material without express
                          permission is prohibited.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default AboutIndex;
