import { Button, Card, Col, Modal, Row, Tabs, Skeleton } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { Container, ModalBody } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Dollarsymbol from "../../assets/images/dollar-symbol.svg";
import Chart from "../../assets/images/grafh.jpg";
import Graph from "../../assets/images/graph.png";
import Righterrow from "../../assets/images/right-errow.png";
import AngleRight from "../../assets/images/icon/angle-right.svg";
import Paymentmethod from "../../assets/images/payment-method.svg";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import { dateString, formatNumber } from "../../helper/functions";

import Dollor from "../../assets/images/component-doller.svg";
import componentgold from "../../assets/images/component-gold.svg";
import componentsilvar from "../../assets/images/component-silvar.svg";
import BreakDownChart from "../../components/chart/_BreakDownChart";
import TimeLineChart from "../../components/chart/_TimeLineChart";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import axios from "axios";

const dummy = {
  labels: [
    lang("Jan"),
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: lang("Timeline"),
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ], // Starting from June
      borderColor: "#F3E008",
      tension: 0.1,
      spanGaps: true, // Add this option
    },
  ],
};

function Portfolio() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [breakDown, setBreakDown] = useState();
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext();
  const [customerGraphData, setGraphCustomerData] = useState({});
  const {
    language,
    goldPrice: currentGoldPrice,
    silverPrice: currentSilverPrice,
  } = useAppContext();

  const [gold, setGold] = useState();
  const [goldPrice, setGoldPrice] = useState({
    current: 0,
    invensted: 0,
  });

  const [silverPrice, setSilverPrice] = useState({
    current: 0,
    invensted: 0,
  });
  const [silver, setSilver] = useState();

  const [tabValue, setTabValue] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    customerMonthlyCount({});
  }, []);

  const handleTabChange = (status) => {
    setTabValue(status);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!userProfile) return;

    if (!userProfile.metalBalance) return;
    let total = 0;
    userProfile.metalBalance.map((item) => {
      if (item.categoryId == "66069035fa639b23dcf4b3a6") {
        const price = item.quantity * item.currentPrice;
        setGoldPrice({
          current: price,
          invensted: item.quantity / item.investedPrice,
        });
        total = total + price;
        setGold(item);
      } else {
        const price = item.quantity * item.currentPrice;
        total = total + price;
        setSilverPrice({
          current: price,
          invensted: item.quantity / item.investedPrice,
        });
        setSilver(item);
      }
    });
  }, [userProfile]);

  const getVault = () => {
    setLoading(true);
    request({
      url: `/app/metal/getBreakdown`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        setBreakDown(data);
      },
      onError: () => setLoading(false),
    });
  };

  useEffect(() => {
    getVault();
  }, []);

  console.log(gold?.quantity * currentGoldPrice, "gold-------->");
  console.log(currentGoldPrice, "currentGoldPrice");
  console.log(currentSilverPrice, "currentSilverPrice");

  const customerMonthlyCount = (data) => {
    const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
    const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

    const customerChartData = {
      series: [
        {
          name: "User",
          data: Ccounts,
        },
      ],
      options: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: Cmonths,
        },
      },
    };
    setGraphCustomerData(customerChartData);
  };

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="table-headding">{lang("Portfolio")}</div>
              </Col>

              <Col sm={24} md={24} className="mt-3">
                <Tabs className="portfolio-tab" onTabClick={handleTabChange}>
                  <TabPane tab={lang("Breakdown")} key="Timesheet">
                    <Row gutter={16}>
                      <Col span={24} md={8}>
                        <div className="graf-tital">
                          <h6>
                            {lang("Graphical Representation")}
                            <img src={AngleRight} />
                          </h6>
                        </div>
                        <Card>
                          <BreakDownChart
                            gold={breakDown?.goldPercent}
                            silver={breakDown?.silverPercent}
                          />
                        </Card>
                      </Col>
                      <Col span={24} md={16}>
                        <div className="graf-tital">
                          <h6>
                            {lang("Summary")} <img src={AngleRight} />
                          </h6>
                        </div>
                        <Card>
                          {gold ? (
                            <div className="summery-card">
                              <div className="sumery-icon">
                                <img src={componentgold} />
                              </div>
                              <div className="curncy-type">
                                <div className="summery-header">
                                  <div className="summery-text-headding gold">
                                    {lang("Gold")}
                                  </div>
                                  <div className="summery-right ">
                                    <h6>
                                      $
                                      {formatNumber(
                                        (
                                          gold.quantity * currentGoldPrice
                                        ).toFixed(2)
                                      )}
                                    </h6>

                                    <span
                                      className={`${
                                        gold.profit <= 0
                                          ? "main-new-red1"
                                          : "main-new-green1"
                                      }`}
                                    >
                                      {gold.profit <= 0
                                        ? `${formatNumber(
                                            (
                                              (gold.loss * 100) /
                                              gold.investedPrice
                                            ).toFixed(2)
                                          )}`
                                        : `${formatNumber(
                                            (
                                              (gold.profit * 100) /
                                              gold.investedPrice
                                            ).toFixed(2)
                                          )}`}
                                      %
                                    </span>
                                  </div>
                                </div>
                                <div className="summery-footer">
                                  <div className="summery-footer-left">
                                    <h6>
                                      $
                                      {formatNumber(
                                        (
                                          gold.quantity * currentGoldPrice
                                        ).toFixed(2)
                                      )}
                                    </h6>
                                    <span>
                                      {lang("Total Acquisition Cost")}
                                    </span>
                                  </div>
                                  <div className="summery-footer-right">
                                    <h6>
                                      {formatNumber(gold.quantity.toFixed(4))}
                                      (OZ)
                                    </h6>
                                    <span>{lang("Total Owned")}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {silver ? (
                            <div className="summery-card">
                              <div className="sumery-icon">
                                <img src={componentsilvar} />
                              </div>
                              <div className="curncy-type">
                                <div className="summery-header">
                                  <div className="summery-text-headding silver">
                                    {lang("Silver")}
                                  </div>
                                  <div className="summery-right ">
                                    <h6>
                                      $
                                      {formatNumber(
                                        (
                                          silver.quantity * currentSilverPrice
                                        ).toFixed(2)
                                      )}
                                    </h6>
                                    <span
                                      className={`${
                                        silver.profit < 0
                                          ? "main-new-red1"
                                          : "main-new-green1"
                                      }`}
                                    >
                                      {silver.profit <= 0
                                        ? `${formatNumber(
                                            (
                                              (silver.loss * 100) /
                                              silver.investedPrice
                                            ).toFixed(2)
                                          )}`
                                        : `${formatNumber(
                                            (
                                              (silver.profit * 100) /
                                              silver.investedPrice
                                            ).toFixed(2)
                                          )}`}
                                      %
                                    </span>
                                  </div>
                                </div>
                                <div className="summery-footer">
                                  <div className="summery-footer-left">
                                    <h6>
                                      $
                                      {formatNumber(
                                        (
                                          silver.quantity * currentSilverPrice
                                        ).toFixed(2)
                                      )}
                                    </h6>
                                    <span>
                                      {lang("Total Acquisition Cost")}
                                    </span>
                                  </div>
                                  <div className="summery-footer-right">
                                    <h6>
                                      {formatNumber(silver.quantity.toFixed(4))}
                                      (OZ)
                                    </h6>
                                    <span>{lang("Total Owned")}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="summer-total-in">
                            <div className="sumery-icon d-flex align-items-center gap-2">
                              <img src={Dollor} />
                              <h6>{lang("Cash Balance")}</h6>
                            </div>

                            <h6>{formatNumber(userProfile?.cashBalance)}</h6>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab={lang("Timeline")} key="LeaveRequest">
                    <TransactionList />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

const TransactionList = ({}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [timeLine, setTimeLine] = useState();
  const { language } = useAppContext();
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { isLoggedIn } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState();

  const showModal = (data) => {
    setIsModalOpen(true);
    navigate(`/transaction/detail`, { state: data });
  };

  const { request } = useRequest();

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/transaction/getTransaction`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setData(data.data);
        setLoading(false);
      },
      onError: (err) => setLoading(false),
    });
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    getResources();
  }, [isLoggedIn]);

  useEffect(() => {}, [data]);

  const getVault = () => {
    setLoading(true);
    request({
      url: `/app/metal/getTimeline?baseCurrency=USD`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        let labels = [];
        let dataset = [];

        data.data.map((item) => {
          labels.push(item.date);
          dataset.push(item.price);
        });

        const dummy = {
          labels,
          datasets: [
            {
              label: lang("Timeline"),
              data: dataset, // Starting from June
              borderColor: "#F3E008",
              tension: 0.1,
              spanGaps: true, // Add this option
            },
          ],
        };

        setTimeLine(dummy);
        // let labels=[]
      },
      onError: () => setLoading(false),
    });
  };

  useEffect(() => {
    getVault();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={24} lg={10} className="order-2 order-lg-1  mt-ordr">
          <div className="graf-tital">
            <h6>
              {lang("Recent Transactions")} {""} <img src={AngleRight} />
            </h6>
          </div>
          <Card className="graf-list-items">
            {loading
              ? [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
              : data?.map((item) => (
                  <div
                    key={item?._id}
                    className="Transactions-header"
                    onClick={() => {
                      setSelected(data);
                      showModal(item);
                    }}
                  >
                    <div className="Transactions-text-headding">
                      <div className="transactions-list">
                        <h6>
                          {item?.orderId?.typeOfTransaction === "Buy" ? (
                            <>
                              {language == "en"
                                ? `${lang("Bought")} ${
                                    item?.orderId?.categoryId?.categories
                                  }`
                                : `${lang("Bought")} ${
                                    item?.orderId?.categoryId?.ar_categories ||
                                    "-"
                                  }`}
                            </>
                          ) : (
                            `${lang("Bought")} ${
                              item?.recurring_orderId?.categoryId?.categories
                            }`
                          )}

                          {item?.orderId?.typeOfTransaction == "Sell" && (
                            <>
                              {" "}
                              {lang("Sell")}{" "}
                              {language == "en"
                                ? item?.orderId?.categoryId?.categories
                                : item?.orderId?.categoryId?.ar_categories}{" "}
                            </>
                          )}
                          {item?.orderId?.typeOfTransaction == "Swap" && (
                            <>
                              {" "}
                              {lang("Swap")}{" "}
                              {language == "en"
                                ? item?.orderId?.categoryId?.categories
                                : item?.orderId?.categoryId?.ar_categories}{" "}
                            </>
                          )}
                        </h6>
                        <span>{dateString(item?.created_at)}</span>
                        {/* <p>24 hours ago <span>Complete</span></p> */}
                      </div>
                    </div>
                    <div className="Transactions-right ">
                      <div>
                        <h6>
                          {item?.amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </h6>
                        <span>{item?.quantity?.toFixed(3)} oz</span>
                      </div>
                      <div className="next-dd">
                        <img width={7} src={Righterrow} />
                      </div>
                    </div>
                  </div>
                ))}
          </Card>
        </Col>
        <Col span={24} lg={14} className="order-1 order-lg-2">
          <div className="graf-tital">
            <h6>
              {lang("Graphical Representation")} <img src={AngleRight} />
            </h6>
          </div>
          <Card className="port-graf">
            <TimeLineChart data={timeLine} />
          </Card>
        </Col>
      </Row>
      <Modal
        width={670}
        className="modal01"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <ModalBody>
          <div className="portfolio-headding">
            <span>{lang("Bought")}</span>
            <h2>{lang("Silver")}</h2>
            <h6>0.767 oz</h6>
          </div>
          <div className="invest-list">
            <ul class="invest-list-item">
              <li class="list-item-iner">
                <span>{lang("Date")}</span>
                <p>11/25/2023, 6:00 PM</p>
              </li>
              <li class="list-item-iner">
                <span>{lang("Status")}</span>
                <p className="text-success ">{lang("Completed")}</p>
              </li>
              <li class="list-item-iner">
                <span>{lang("Payment method")}</span>
                <img src={Paymentmethod} />
              </li>
              <li class="list-item-iner total-count">
                <span>{lang("Subtotal")}</span>
                <span>$8.67</span>
              </li>
              <li class="list-item-iner">
                <span>{lang("Fee")}</span>
                <p>$0.99</p>
              </li>
              <li class="list-item-iner">
                <span>{lang("Platform fee")}</span>
                <p>$0.34</p>
              </li>
              <li class="list-item-iner total-count">
                <span>{lang("Total")}</span>
                <span>$10.00</span>
              </li>
            </ul>
          </div>
          <div className="d-flex gap-3 mt-3">
            <Button className="visa-in-btn portfolio-btn" type="button">
              {" "}
              {lang("Close")}{" "}
            </Button>
            <Button className="ant-btn-default portfolio-btn" type="button">
              {" "}
              {lang("Auto Invest")}{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Portfolio;
