import { Card, Col, Row, Dropdown, Space, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useNavigate, Link } from "react-router-dom";

import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import Rightmark from "../../assets/images/checkmark.svg";
import User from "../../assets/images/user.png";
import EditIcon from "../../assets/images/edit-icon.png";
import { dateString, formatNumber } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/langHelper";

function NotificationsIndex() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { userProfile } = useAuthContext();
  const [list, setList] = useState([]);

  const navigate = useNavigate();

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/notifications/get-notifications`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        if (data.data.length) {
          setList(data.data);
        }
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  const onDelete = (id) => {
    setDeleing(true);
    request({
      url: `/app/notifications/delete-notification/${id}`,
      method: "PUT",
      onSuccess: (res) => {
        const { data, message } = res;
        ShowToast(message, Severty.SUCCESS);
        setDeleing(false);
        setRefresh((prev) => !prev);
      },
      onError: (err) => {
        ShowToast(err, Severty.SUCCESS);
        setDeleing(false);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, [refresh, deleting]);

  const readNotifcation = (item) => {
    if (item) {
      if (item?.is_read === false) {
        request({
          url: `/app/notifications/read-notification/${item?._id}`,
          method: "PUT",
          onSuccess: ({ data }) => {
            ShowToast(
              lang("Notification  Read  Successfully"),
              Severty.SUCCESS
            );
            setRefresh((prev) => !prev);
          },
          onError: (err) => {
            console.log(err);
          },
        });
      } else {
        console.log("Already Read");
      }
    } else {
      request({
        url: `/app/notifications/read-notification`,
        method: "PUT",
        onSuccess: ({ data }) => {
          ShowToast(
            lang("Notifications All Read  Successfully"),
            Severty.SUCCESS
          );
          setRefresh((prev) => !prev);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <Card>
                  <div className="notify-header ">
                    <Link onClick={(e) => readNotifcation()}>
                      {lang("Mark as read all")} <img src={Rightmark} />
                    </Link>
                  </div>
                  <div className="nottfy-body">
                    <ul>
                      <Accordion defaultActiveKey="0" className="p-0">
                        {loading
                          ? [1, 2, 3, 4].map((item) => (
                              <Skeleton key={item} active />
                            ))
                          : list.map((item, index) => (
                              <Accordion.Item
                                key={item._id}
                                eventKey={index}
                                className={`notify-main ${
                                  item.is_read
                                    ? "read-notification"
                                    : "unread-notification"
                                }`}
                              >
                                <Accordion.Header
                                  className="notfy-acco"
                                  onClick={(e) => readNotifcation(item)}
                                >
                                  <span
                                    style={{
                                      fontWeight: item.is_read
                                        ? "normal"
                                        : "bold",
                                      color: item.is_read
                                        ? "rgb(177 186 193)"
                                        : "#fff", // Read: gray, Unread: black
                                    }}
                                  >
                                    {item.message.replace(/\d+/, (match) => {
                                      const formattedNumber = formatNumber(
                                        parseInt(match, 10)
                                      );
                                      return formattedNumber;
                                    })}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body className="faq-body-text">
                                  <li className="unread">
                                    <div className="n-nameuser">
                                      <h5
                                        style={{
                                          color: item.is_read
                                            ? "#6c757d"
                                            : "#000",
                                        }}
                                      >
                                        {item.title}
                                      </h5>
                                      <span>
                                        {dateString(item.created_at, "lll")}
                                      </span>
                                    </div>
                                    <div className="edite-notty">
                                      <Dropdown
                                        menu={{
                                          items: [
                                            {
                                              label: (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    onDelete(item._id)
                                                  }
                                                >
                                                  {lang("Delete")}
                                                </div>
                                              ),
                                              key: "0",
                                            },
                                          ],
                                        }}
                                        trigger={["click"]}
                                      >
                                        <Link
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <Space>
                                            <img src={EditIcon} />
                                          </Space>
                                        </Link>
                                      </Dropdown>
                                    </div>
                                  </li>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                      </Accordion>
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default NotificationsIndex;
