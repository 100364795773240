import { Col, Row, Skeleton } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";

function FaqIndex() {
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { language } = useAppContext();
  const navigate = useNavigate();

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/content/get-content/faq`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setLoading(false);
        if (data.data.length) {
          setList(data.data[0].faq);
        }
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="tital transactions-tital ">
                  <h4 className="m-0">{lang("FAQ")}</h4>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  {loading &&
                    [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)}
                  <Accordion defaultActiveKey="0" className="p-0">
                    {list.map((item, index) => (
                      <Accordion.Item eventKey={index} className="faq-main">
                        <Accordion.Header className="faq-tital">
                          {language == "en" ? item.question : item.es_question}
                        </Accordion.Header>
                        <Accordion.Body className="faq-body-text">
                          {language == "en" ? item.answer : item.es_answer}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default FaqIndex;
