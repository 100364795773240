import React, { createContext, useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useAuthContext } from "./AuthContext";
export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { request } = useRequest();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [bannerList, setBannnerList] = useState([]);
  const [prices, setPrices] = useState([]);
  const [vault, setVaulat] = useState([]);
  const [goldPrice, setGoldPrice] = useState(0);
  const [goldRates, setGoldRates] = useState();
  const [prevGoldPrice, setPrevGoldPrice] = useState(null);
  const [goldPriceDifference, setGoldPriceDifference] = useState(0);
  const [silverPrice, setSilverPrice] = useState(0);
  const [silverRates, setSilverRates] = useState();
  const [prevSilverPrice, setPrevSilverPrice] = useState(null);
  const [silverPriceDifference, setSilverPriceDifference] = useState(0);
  const [serviceFee, setServiceFee] = useState();
  const [refreshBalance, setRefreshBalance] = useState(false);
  const [metal, setMetal] = useState([]);
  const [language, setLanguage] = useState("en");

  const [theme, setTheme] = useState(true);

  const { isLoggedIn, setUserProfile } = useAuthContext();

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    let lang = localStorage.getItem("languageSet");
    lang = lang ? lang : "en";

    // let theme = localStorage.setItem("theme", "true");
    // theme = theme ? theme : "true";
    // setTheme(theme);
    // setTimeout(() => setLoading(false), 200);
    setLanguage(lang);
  }, []);

  useEffect(() => {
    if (prices.length) return;
    request({
      url: "/common/product-listing",
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setPrices(data.data);
          setMetal(data.data);
        }
      },
      onError: (error) => {},
    });

    request({
      url: "/admin/service-fees/fees",
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (data.data.length) {
          setServiceFee(data.data[0]);
        }
      },
      onError: (error) => {},
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;
    request({
      url: "/app/vault/getVaultList",
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setVaulat(data.data);
        }
      },
      onError: (error) => {},
    });
    // Additional logic on login
  }, [isLoggedIn]);

  const getGold = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.nfusionsolutions.biz/api/v1/Metals/spot/summary?metals=Gold%2CSilver&currency=USD&unitofmeasure=toz&format=json&token=ab70fcff-aa4f-4b83-ad44-5d29b1bfbb1b",
    };

    axios
      .request(config)
      .then((response) => {
        let livePrice;
        response = response.data[0]?.data?.ask;

        livePrice = response;

        if (prevGoldPrice !== null) {
          const difference = livePrice - prevGoldPrice;
          setGoldPriceDifference(difference);
        }
        setPrevGoldPrice(goldPrice);

        setGoldPrice(livePrice);
        setGoldRates(livePrice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGold();
    const intervalId = setInterval(() => {
      getGold();
    }, 10000); // Call the API every 10 seconds
    return () => clearInterval(intervalId); // Clear interval on unmount
  }, []); // Rerun the effect when goldPrice changes

  const getSilver = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.nfusionsolutions.biz/api/v1/Metals/spot/summary?metals=Gold%2CSilver&currency=USD&unitofmeasure=toz&format=json&token=ab70fcff-aa4f-4b83-ad44-5d29b1bfbb1b",
    };

    axios
      .request(config)
      .then((response) => {
        let livePrice;

        response = response.data[1]?.data?.ask;

        livePrice = response;

        if (prevSilverPrice !== null) {
          const difference = livePrice - prevSilverPrice;
          setSilverPriceDifference(difference);
        }

        setPrevSilverPrice(silverPrice);
        setSilverPrice(livePrice);
        setSilverRates(livePrice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // getGold();
      getSilver();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [silverPrice]);

  return (
    <AppStateContext.Provider
      value={{
        bannerList,
        password,
        email,
        setEmail,
        setPassword,
        prices,
        goldPrice,
        goldRates,
        goldPriceDifference,
        silverPrice,
        silverRates,
        silverPriceDifference,
        serviceFee,
        refreshBalance,
        setRefreshBalance,
        isMobile,
        metal,
        language,
        setLanguage,
        setTheme,
        theme,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};
