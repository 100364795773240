import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tabs,
  Typography,
} from "antd";

import DeleteOutlined from "antd/es/icon";
import { ShowToast, Severty } from "../../helper/toast";
import "react-phone-input-2/lib/style.css";

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";

import { useNavigate } from "react-router-dom";
import encryptDecrypt from "../../helper/encryptDecrypt";
import useDebounce from "../../hooks/useDebounce";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { dateString } from "../../helper/functions";
import { useAppContext } from "../../context/AppContext";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function Security() {
  const { request } = useRequest();
  const { Title } = Typography;
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const { isMobile } = useAppContext();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [tabValue, setTabValue] = useState();
  const [devices, setDevices] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { old_password, new_password } = values;

    setLoading(true);

    request({
      url: "/app/auth/change-password",
      method: "POST",
      data: { old_password, new_password },
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({
            old_password: null,
            new_password: null,
            confirm: null,
          });
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const columns = [
    {
      title: lang("Confirmed"),
      className: "devices-menegment",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: lang("Browser"),
      className: "devices-menegment",
      dataIndex: "Browser",
      key: "Browser",
    },
    {
      title: lang("IP Address"),
      className: "devices-menegment",
      dataIndex: "number",
      key: "number",
    },
    {
      title: lang("Location"),
      className: "devices-menegment",
      dataIndex: "address",
      key: "address",
    },
    {
      title: lang("Current"),
      className: "devices-menegment",
      dataIndex: "answer",
      key: "answer",
    },

    {
      title: "",
      key: lang("action"),
      render: (_, record) => (
        <Space size="middle">
          <Button>{lang("Delete")}</Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      className: "tdmenegment",
      name: "5 minutes ago",
      Browser: "Firefox (Window)",
      number: 104219232126,
      address: "United States",
      answer: "yes",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      className: "tdmenegment",
      name: "about 6 hours ago",
      Browser: "Chrome (Mac Apple)",
      number: 104219232126,
      address: "Jaipur, India",
      answer: "No",
      tags: ["nice", "developer"],
    },
  ];

  const onDelete = (id) => {
    setDeleting(true);
    request({
      url: `/app/devices/${id}`,
      method: "DELETE",
      onSuccess: (data) => {
        setDeleting(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefresh((prev) => !prev);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setDeleting(false);
      },
    });
  };

  const deviceColums = [
    {
      title: lang("Confirmed"),
      className: "devices-menegment",
      dataIndex: "name",
      key: "name",
      render: (_, { dateTime }) => <a>{dateString(dateTime, "ll")}</a>,
    },
    {
      title: lang("Browser"),
      className: "devices-menegment",
      dataIndex: "Browser",
      key: "Browser",
      render: (_, { browser }) => <a>{browser}</a>,
    },
    {
      title: lang("IP Address"),
      className: "devices-menegment",
      dataIndex: "number",
      key: "number",
      render: (_, { ipAddress }) => <a>{ipAddress}</a>,
    },
    {
      title: lang("Location"),
      className: "devices-menegment",
      dataIndex: "address",
      key: "address",
      render: (_, { location }) => <a>{location}</a>,
    },
    {
      title: lang("Current"),
      className: "devices-menegment",
      dataIndex: "answer",
      key: "answer",
      render: (_, { current }) => <a>{current ? "Yes" : "No"}</a>,
    },

    {
      title: "",
      key: lang("action"),
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => onDelete(record._id)}>{lang("Delete")}</Button>
        </Space>
      ),
    },
  ];

  const getDevices = () => {
    setLoading(true);
    request({
      url: "/app/devices/get-devices",
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setLoading(false);
          // ShowToast(data.message, Severty.SUCCESS)
          setDevices(data.data.data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleOldPasswordChange = () => {
    // Trigger validation for the new_password field
    form.validateFields(["new_password"]);
  };

  useEffect(() => {
    getDevices();
    setLoading(true);
  }, [refresh]);

  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">{lang("Security")}</div>
                <div className="profile-card security-card">
                  <div className="signup-form security-form">
                    <Title className="tital-text-sign  ">
                      {lang("Change Password")}
                    </Title>
                    <Form
                      form={form}
                      onFinish={onFinish}
                      layout="vertical"
                      className="row-col"
                    >
                      <Row gutter={12}>
                        <Col span={24} md={12}>
                          <Form.Item
                            className="username-form billing-form"
                            label={lang("Current Password")}
                            name="old_password"
                            rules={[
                              {
                                required: true,
                                message: lang("Please enter the old password!"),
                              },
                            ]}
                          >
                            <Input.Password
                              className="form-of-type"
                              placeholder={lang("Current Password")}
                              onChange={handleOldPasswordChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} md={12}></Col>
                        <Col span={24} md={12}>
                          <Form.Item
                            className="username-form billing-form"
                            label={lang("New Password")}
                            name="new_password"
                            rules={[
                              {
                                required: true,
                                message: lang("Please enter the new password!"),
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("old_password") === value
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        lang(
                                          "Old password & new password must be different!"
                                        )
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              className="form-of-type"
                              placeholder={lang("New Password")}
                              onChange={handleOldPasswordChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                          <Form.Item
                            className="username-form billing-form"
                            label={lang("Confirm Password")}
                            name="confirm"
                            dependencies={["new_password"]}
                            // hasFeedback
                            rules={[
                              {
                                required: true,
                                message: lang(
                                  "Please enter the confirm password!"
                                ),
                              },
                              // {
                              //   pattern: new RegExp(
                              //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                              //   ),
                              //   message:
                              //     "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                              // },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("new_password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      lang(
                                        "Confirm password & password does not match!"
                                      )
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              className="form-of-type"
                              placeholder={lang("Password")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="password-page-btn">
                        <Form.Item>
                          <Button htmlType="submit" className="security-btn">
                            {lang("Update Password")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                  <div className="devices-manegment ">
                    <Title className="tital-text-sign">{lang("Devices")}</Title>
                    <span className="sub-titals">
                      {lang(
                        "These devices are currently allowed to access your account."
                      )}
                    </span>

                    <div className="table-manegments">
                      <Table
                        className="devices-table"
                        columns={deviceColums}
                        dataSource={devices}
                        pagination={true}
                        loading={loading}
                      />
                    </div>
                  </div>
                  {/* <div className="devices-manegment ">
                    <Title className="tital-text-sign">{lang("Activity")}</Title>
                    <span className="sub-titals">
                      {lang("Recent activity on your account.")}
                    </span>

                    <div className="table-manegments">
                      <Table
                        className="devices-table"
                        columns={columns}
                        dataSource={data}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Security;
