import { Badge, Button, Col, Row, Skeleton } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";

function IndexPricce() {
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);

  const [vault, vaultList] = useState([]);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const getVault = () => {
    setLoading(true);
    request({
      url: `/app/vault/getVaultList`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        vaultList(
          data.data.sort((a, b) =>
            a.categoryId.categories.localeCompare(b.categoryId.categories)
          )
        );
        setLoading(false);
      },
      onError: () => setLoading(false),
    });
  };

  useEffect(() => {
    getVault();
  }, []);

  return (
    <>
      <section className="main">
        <Header />
        <div className="gold-section">
          <Row className="justify-content-center">
            <Col span={24} md={12} lg={8} className="text-center">
              <div className="price-outers-main">
                <div className="cc-icon">
                  <svg
                    width="684"
                    height="476"
                    viewBox="0 0 684 476"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.7333 3.46668C17.7333 6.40002 8.93333 14.6667 5.2 22.9334L2 30V238.667V447.333L4.8 453.333C8.66667 461.467 15.2 467.733 24 471.867L31.3333 475.333H342.667H654L660.667 472.267C672.8 466.667 680.133 457.333 682.667 444.533C683.733 438.8 684 380.133 683.733 233.333L683.333 30L680.533 23.8667C676.667 15.4667 670.667 9.20002 663.067 5.33335L656.667 2.00002L344 1.73335C70.6667 1.60002 30.6667 1.73335 25.7333 3.46668ZM384 147.333V196H432.667H481.333V238V280H432.667H384V328.667V377.333H342.667H301.333V328.667V280H252.667H204V238V196H252.667H301.333V147.333V98.6667H342.667H384V147.333Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M384.5 98.5H301V197H203V281.5H301V378.5H384.5V281.5H481V197H384.5V98.5Z"
                      fill="white"
                    />
                  </svg>{" "}
                </div>
                <h1>Switzerland Products</h1>
                <p>
                  {" "}
                  Switzerland Products are investment-grade physical gold,
                  silver, and platinum products that are allocated, insured,
                  audited, and stored with one of our premier storage
                  partners.Switzerland Products is stored with Loomis
                  International approved vaults
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="curve-shape">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div className="list-pricing-main">
          <Container>
            <Row className=" justify-content-center">
              {loading
                ? [1, 2, 3, 4, 5].map((item) => <Skeleton active key={item} />)
                : vault.map((item, index) => (
                    <Col span={24} md={12} lg={8} className="mb-3" key={index}>
                      <div className="pricing-card">
                        <div className="headding-pri">
                          <Badge className="bg-secondary" title="">
                            {item.categoryId.categories}
                          </Badge>
                        </div>
                        <div className="price-body-t">
                          <h6>${item.buyPricePerOz}</h6>
                          <p>per oz</p>
                        </div>
                        <ul className="list-pricing">
                          <li className="price-list-items">
                            <p className="location-name">Vault Location</p>
                            <div className="location-icon">
                              <svg
                                width="684"
                                height="476"
                                viewBox="0 0 684 476"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M25.7333 3.46668C17.7333 6.40002 8.93333 14.6667 5.2 22.9334L2 30V238.667V447.333L4.8 453.333C8.66667 461.467 15.2 467.733 24 471.867L31.3333 475.333H342.667H654L660.667 472.267C672.8 466.667 680.133 457.333 682.667 444.533C683.733 438.8 684 380.133 683.733 233.333L683.333 30L680.533 23.8667C676.667 15.4667 670.667 9.20002 663.067 5.33335L656.667 2.00002L344 1.73335C70.6667 1.60002 30.6667 1.73335 25.7333 3.46668ZM384 147.333V196H432.667H481.333V238V280H432.667H384V328.667V377.333H342.667H301.333V328.667V280H252.667H204V238V196H252.667H301.333V147.333V98.6667H342.667H384V147.333Z"
                                  fill="#FF0000"
                                />
                                <path
                                  d="M384.5 98.5H301V197H203V281.5H301V378.5H384.5V281.5H481V197H384.5V98.5Z"
                                  fill="white"
                                />
                              </svg>{" "}
                            </div>
                          </li>
                          <li className="price-list-items">
                            <p className="location-name">Allocation</p>
                            <h6>Fully allocated and physically segregated</h6>
                          </li>
                          {/* <li className="price-list-items">
                                                        <p className="location-name">
                                                            Premium
                                                        </p>
                                                        <h6>${item.premium} per oz or {item.premiumPercantage}%</h6>
                                                    </li> */}
                          <li className="price-list-items">
                            <p className="location-name">Buy Price Per Oz</p>
                            <h6>${item.buyPricePerOz}</h6>
                          </li>
                          <li className="price-list-items">
                            <p className="location-name">Sell Price Per Oz</p>
                            <h6>${item.sellPricePerOz}</h6>
                          </li>
                          {/* <li className="price-list-items">
                                                        <p className="location-name">
                                                            Spread
                                                        </p>
                                                        <h6>${item.spread}</h6>
                                                    </li> */}
                          <li className="price-list-items">
                            <p className="location-name">Storage</p>
                            <h6>
                              {item.storageFee}% per year billed quarterly
                            </h6>
                            <Link to="/storage-fees">
                              Calculate storage costs
                            </Link>
                          </li>
                          <li className="price-list-items">
                            <p className="location-name">Verified by</p>
                            <h6>{item.verifiedBy}</h6>
                          </li>
                          <li className="price-list-items">
                            <p className="location-name">Insurance</p>
                            <h6>{item.insurance}</h6>
                          </li>
                          <li className="price-list-items">
                            <p className="location-name">Minimum Storage Fee</p>
                            <h6>${item.minimumStorageFee / 4} per quarter</h6>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  ))}
              {/* <Col span={24} md={6}>
                                <div className="pricing-card">
                                    <div className="headding-pri">
                                        <Badge className="bg-secondary"  title="" >U.S. Gold </Badge>
                                    </div>
                                    <div className="price-body-t">
                                        <h6>$2,363.46</h6>
                                        <p>per oz</p>
                                    </div>
                                    <ul className="list-pricing">
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                Vault Location
                                            </p>
                                            <div className="location-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#eee"></circle><path fill="#d80027" d="M244.9 256H512c0-23.1-3-45.5-8.8-66.8H244.9V256zm0-133.6h229.5a257.4 257.4 0 0 0-59-66.7H244.9v66.7zM256 512c60.2 0 115.6-20.8 159.4-55.7H96.6A254.9 254.9 0 0 0 256 512zM37.6 389.6h436.8a254.5 254.5 0 0 0 28.8-66.8H8.8a254.5 254.5 0 0 0 28.8 66.8z"></path><path fill="#0052b4" d="M118.6 40h23.3l-21.7 15.7 8.3 25.6-21.7-15.8-21.7 15.8 7.2-22a257.4 257.4 0 0 0-49.7 55.3h7.5l-13.8 10a255.6 255.6 0 0 0-6.2 11l6.6 20.2-12.3-9a253.6 253.6 0 0 0-8.4 20l7.3 22.3H50L28.4 205l8.3 25.5L15 214.6l-13 9.5A258.5 258.5 0 0 0 0 256h256V0c-50.6 0-97.7 14.7-137.4 40zm9.9 190.4l-21.7-15.8-21.7 15.8 8.3-25.5L71.7 189h26.8l8.3-25.5 8.3 25.5h26.8l-21.7 16 8.3 25.5zm-8.3-100l8.3 25.4-21.7-15.7-21.7 15.7 8.3-25.5-21.7-15.7h26.8l8.3-25.6 8.3 25.6h26.8l-21.7 15.7zm100.1 100l-21.7-15.8-21.7 15.8 8.3-25.5-21.7-15.8h26.8l8.3-25.5 8.3 25.5h26.8L212 205l8.3 25.5zm-8.3-100l8.3 25.4-21.7-15.7-21.7 15.7 8.3-25.5-21.7-15.7h26.8l8.3-25.6 8.3 25.6h26.8L212 130.3zm0-74.7l8.3 25.6-21.7-15.8L177 81.3l8.3-25.6L163.5 40h26.8l8.3-25.5L207 40h26.8L212 55.7z"></path></svg>
                                            </div> 
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Allocation
                                            </p>
                                            <h6>Fully allocated and physically segregated</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                 Premium
                                            </p>
                                            <h6>$18.76 per oz or 0.80%</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Buy Price Per Oz
                                            </p>
                                            <h6>$2,363.46</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Sell Price Per Oz
                                            </p>
                                            <h6>$2,322.71</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Spread
                                            </p>
                                            <h6>$40.75</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Storage
                                            </p>
                                            <h6>0.12% per year billed quarterly</h6>
                                            <Link>Calculate storage costs</Link>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Verified by
                                            </p>
                                            <h6>Top 10 Accounting Firm</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Insurance
                                            </p>
                                            <h6>Lloyd's of London</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                Minimum Storage Fee
                                            </p>
                                            <h6>$5.00 per quarter</h6>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col span={24} md={6}>
                                <div className="pricing-card">
                                    <div className="headding-pri">
                                        <Badge className="bg-secondary"  title="" >U.S. Gold </Badge>
                                    </div>
                                    <div className="price-body-t">
                                        <h6>$2,363.46</h6>
                                        <p>per oz</p>
                                    </div>
                                    <ul className="list-pricing">
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                Vault Location
                                            </p>
                                            <div className="location-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#eee"></circle><path fill="#d80027" d="M244.9 256H512c0-23.1-3-45.5-8.8-66.8H244.9V256zm0-133.6h229.5a257.4 257.4 0 0 0-59-66.7H244.9v66.7zM256 512c60.2 0 115.6-20.8 159.4-55.7H96.6A254.9 254.9 0 0 0 256 512zM37.6 389.6h436.8a254.5 254.5 0 0 0 28.8-66.8H8.8a254.5 254.5 0 0 0 28.8 66.8z"></path><path fill="#0052b4" d="M118.6 40h23.3l-21.7 15.7 8.3 25.6-21.7-15.8-21.7 15.8 7.2-22a257.4 257.4 0 0 0-49.7 55.3h7.5l-13.8 10a255.6 255.6 0 0 0-6.2 11l6.6 20.2-12.3-9a253.6 253.6 0 0 0-8.4 20l7.3 22.3H50L28.4 205l8.3 25.5L15 214.6l-13 9.5A258.5 258.5 0 0 0 0 256h256V0c-50.6 0-97.7 14.7-137.4 40zm9.9 190.4l-21.7-15.8-21.7 15.8 8.3-25.5L71.7 189h26.8l8.3-25.5 8.3 25.5h26.8l-21.7 16 8.3 25.5zm-8.3-100l8.3 25.4-21.7-15.7-21.7 15.7 8.3-25.5-21.7-15.7h26.8l8.3-25.6 8.3 25.6h26.8l-21.7 15.7zm100.1 100l-21.7-15.8-21.7 15.8 8.3-25.5-21.7-15.8h26.8l8.3-25.5 8.3 25.5h26.8L212 205l8.3 25.5zm-8.3-100l8.3 25.4-21.7-15.7-21.7 15.7 8.3-25.5-21.7-15.7h26.8l8.3-25.6 8.3 25.6h26.8L212 130.3zm0-74.7l8.3 25.6-21.7-15.8L177 81.3l8.3-25.6L163.5 40h26.8l8.3-25.5L207 40h26.8L212 55.7z"></path></svg>
                                            </div> 
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Allocation
                                            </p>
                                            <h6>Fully allocated and physically segregated</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                 Premium
                                            </p>
                                            <h6>$18.76 per oz or 0.80%</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Buy Price Per Oz
                                            </p>
                                            <h6>$2,363.46</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Sell Price Per Oz
                                            </p>
                                            <h6>$2,322.71</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Spread
                                            </p>
                                            <h6>$40.75</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Storage
                                            </p>
                                            <h6>0.12% per year billed quarterly</h6>
                                            <Link>Calculate storage costs</Link>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Verified by
                                            </p>
                                            <h6>Top 10 Accounting Firm</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                            Insurance
                                            </p>
                                            <h6>Lloyd's of London</h6>
                                        </li>
                                        <li className="price-list-items"> 
                                            <p className="location-name">
                                                Minimum Storage Fee
                                            </p>
                                            <h6>$5.00 per quarter</h6>
                                        </li>
                                    </ul>
                                </div>
                            </Col> */}
            </Row>
            <Row className=" justify-content-center mt-4 mb-4">
              <Col span={24} md={12}>
                <Button className="price-btn-last">Buy Now</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default IndexPricce;
