import { Col, Row, Typography } from "antd";
import "react-phone-input-2/lib/style.css";

import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";

import { useNavigate, useLocation } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";

const data1 = {
  transactionId: null,
  customerId: "66434ebdb7db0031c3bc95dc",
  orderId: {
    swapId: null,
    available_withdraw: "24 hours",
    is_status: true,
    is_deleted: false,
    _id: "664ae48291086080700a0947",
    customerId: "66434ebdb7db0031c3bc95dc",
    categoryId: {
      is_status: true,
      is_deleted: false,
      _id: "66069035fa639b23dcf4b3a6",
      categories: "Gold",
      price: 1783.45,
      chance: "High",
      atomicMass: "196.97",
      atomicNumber: "79",
      atomicSymbol: "XAU",
      created_at: "2024-03-29T09:56:05.481Z",
      updated_at: "2024-05-13T07:26:14.409Z",
      __v: 0,
    },
    payment_method: "cashBalance",
    metal_price: 0.0004085945985634,
    swapPrice: 0,
    amount: 700,
    quantity: 0.28458613789940806,
    typeOfTransaction: "Buy",
    fee: 4,
    platform_fee: 0.3,
    total: 696.5,
    networkFees: 1.4,
    processingFees: 2.1,
    created_at: "2024-05-20T05:49:54.559Z",
    updated_at: "2024-05-20T05:50:42.647Z",
    orderId: "7NRGAPCYJDF",
    __v: 0,
    transactionId: "664ae4b291086080700a0954",
  },
  categoryId: {
    is_status: true,
    is_deleted: false,
    _id: "66069035fa639b23dcf4b3a6",
    categories: "Gold",
    price: 1783.45,
    chance: "High",
    atomicMass: "196.97",
    atomicNumber: "79",
    atomicSymbol: "XAU",
    created_at: "2024-03-29T09:56:05.481Z",
    updated_at: "2024-05-13T07:26:14.409Z",
    __v: 0,
  },
  dateOfTransaction: "2024-05-20T05:50:42.635Z",
  is_status: true,
  is_deleted: false,
  _id: "664ae4b291086080700a0954",
  amount: 700,
  quantity: 0.28458613789940806,
  typeOfTransaction: "Buy",
  payment_method: "cashBalance",
  description: "Gold Buy",
  created_at: "2024-05-20T05:50:42.636Z",
  updated_at: "2024-05-20T05:50:42.636Z",
  __v: 0,
};

function TransactionView() {
  const { request } = useRequest();
  const { Title } = Typography;

  const { state } = useLocation();
  const [data, setData] = useState();

  useEffect(() => {
    if (!state) return;

    setData(state);
  }, [state]);

  if (!data) return null;
  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="active-type-modal">
                <ul>
                  <li className="active-type-list">
                    <p>{lang("Description")}</p>
                    <h6>
                      {data.recurring_orderId ? (
                        data?.typeOfTransaction == "AutoBuy" ? (
                          <>
                            {lang("Exchange")} ${lang(" to")}{" "}
                            {data?.description.split(" ")[0]}
                          </>
                        ) : (
                          "Vault Fee"
                        )
                      ) : data.orderId ? (
                        data.orderId.typeOfTransaction === "Buy" ? (
                          <>
                            {lang("Exchange")} ${lang(" to")}{" "}
                            {data.orderId.categoryId.categories}
                          </>
                        ) : (
                          "hh"
                        )
                      ) : (
                        "Vault Fee"
                      )}

                      {data.orderId?.typeOfTransaction == "Sell" && (
                        <>
                          {" "}
                          {lang("Exchange")}{" "}
                          {data.orderId.categoryId.categories} {lang("to")} ${" "}
                        </>
                      )}
                      {data.orderId?.typeOfTransaction == "Swap" && (
                        <>
                          {" "}
                          {lang("Exchange")}{" "}
                          {data.orderId.categoryId.categories} {lang("to")}{" "}
                          {data.orderId.swapId.categories}{" "}
                        </>
                      )}
                      {data.orderId?.typeOfTransaction == "Vault Fee" && (
                        <> {lang("Vault Fee")} </>
                      )}
                    </h6>
                  </li>
                  <li className="active-type-list">
                    <p>{lang("Date")}</p>
                    <h6>{dateString(data.dateOfTransaction)}</h6>
                  </li>
                  <li className="active-type-list">
                    <p>{lang("Amount")}</p>
                    {data.orderId ? (
                      <h6>{data.orderId.quantity?.toFixed(3)} oz</h6>
                    ) : data.recurring_orderId ? (
                      <h6>{data?.quantity?.toFixed(3)} oz</h6>
                    ) : (
                      data?.amount
                    )}
                  </li>

                  {/* <span>
              (
              {data.orderId?.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              )
            </span>{" "} */}
                  {(data?.orderId || data?.recurring_orderId) && (
                    <li className="active-type-list">
                      <p>{lang("type")}</p>
                      {data?.orderId ? (
                        <h6>{data?.orderId?.typeOfTransaction}</h6>
                      ) : data?.recurring_orderId ? (
                        <h6>{data?.recurring_orderId?.typeOfTransaction}</h6>
                      ) : null}
                    </li>
                  )}
                  {(data?.orderId || data?.recurring_orderId) &&
                    data?.orderId?.typeOfTransaction !== "Vault Fee" && (
                      <>
                        <li className="active-type-list">
                          <p>{lang("rate")}</p>
                          {data?.orderId ? (
                            <h6>
                              ${" "}
                              {(1 / (data?.orderId?.metal_price ?? 1)).toFixed(
                                3
                              )}{" "}
                              /OZ
                            </h6>
                          ) : data?.recurring_orderId ? (
                            <h6>
                              ${" "}
                              {(
                                1 / (data?.recurring_orderId?.metal_price ?? 1)
                              ).toFixed(3)}{" "}
                              /OZ
                            </h6>
                          ) : (
                            <h6>$ {(1 / 1).toFixed(3)} /OZ</h6> // Default value when neither orderId nor recurring_orderId exists
                          )}
                        </li>

                        <li className="active-type-list">
                          <p>{lang("Fee")}</p>
                          {data?.orderId ? (
                            <h6>$ {data?.orderId?.processingFees ?? 0}</h6>
                          ) : data?.recurring_orderId ? (
                            <h6>
                              $ {data?.recurring_orderId?.processingFees ?? 0}
                            </h6>
                          ) : (
                            <h6>$ 0</h6> // Default value when neither orderId nor recurring_orderId exists
                          )}
                        </li>
                      </>
                    )}
                  <li className="active-type-list">
                    <p>{lang("Status")}</p>
                    <h6>{lang("Completed")}</h6>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TransactionView;
