import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Row,
  Select,
  Tabs,
} from "antd";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../helper/toast";

import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DawnloadIcon from "../../assets/images/download.svg";
import { DownloadExcel } from "../../components/ExcelFile";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function Transactions() {
  const { request } = useRequest();
  const { isMobile, metal } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("all");
  const [exportLoading, setExportLoading] = useState(false);
  const [metalId, setMetalId] = useState("");

  const [filter, setFilter] = useState({
    start_date: undefined,
    end_date: undefined,
  });

  const handleTabChange = (status) => {
    setTabValue(status);
  };

  const getExportData = async () => {
    try {
      const queryString = Object.entries(filter)
        .filter(([_, v]) => v)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      setExportLoading(true);
      request({
        url: `/app/transaction/getTransaction?category_id=${
          metalId ? metalId : ""
        }${queryString ? `&${queryString}` : ""}`,

        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    if (!exportData.length) {
      ShowToast(lang("You have not made a transaction yet"), Severty.ERROR);
      return;
    }

    const data = exportData.map((row) => ({
      "Transaction Type": row?.typeOfTransaction || "-",
      "Transaction ID": row?._id || "-",
      // "Payment Type": row?.orderId
      //   ? row?.payment_method === "cashBalance"
      //     ? lang("Cash Balance")
      //     : row?.payment_method
      //   : "-",
      "Payment Type":
        row?.payment_method === "cashBalance"
          ? lang("Cash Balance")
          : row?.payment_method,

      Amount: row?.amount ? `$${row?.amount}` : "0",
      "Weight (OZ)": row?.quantity ? `${row?.quantity?.toFixed(3)} Oz` : "-",
      "Date and Time": moment(row.created_at).format("MMMM D, YYYY h:mm A"),
    }));
    DownloadExcel(data, "Transactions");
  };

  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="transactions-header">
                  <div className="table-headding">{lang("Transactions")}</div>
                  <Button
                    className="visa-in-btn "
                    type="button"
                    onClick={() => getExportData()}
                  >
                    <img src={DawnloadIcon} />
                    {lang("Download History")}
                  </Button>
                </div>
                <div className="tital-transactions-tab">
                  <Tabs
                    className="transactions-tab"
                    onTabClick={handleTabChange}
                  >
                    {/* <TabPane
                      tab="All"
                      key="all"
                      className="tab-pane-transaction"
                    >
                      <TransactionTable type={"all"} />
                    </TabPane> */}
                    {/* <TabPane
                      tab="Buy"
                      key="Buy"
                      className="tab-pane-transaction"
                    >
                      <TransactionTable type={"Buy"} />
                    </TabPane> */}
                    {/* <TabPane
                      tab="Sell"
                      key="Sell"
                      className="tab-pane-transaction"
                    >
                      <TransactionTable type={"Sell"} />
                    </TabPane> */}
                    {/* <TabPane
                      tab="Swap"
                      key="Swap"
                      className="tab-pane-transaction"
                    >
                      <TransactionTable type={"Swap"} />
                    </TabPane> */}
                  </Tabs>
                  {/* <div className="right-dropdawn md">
                    <Dropdown
                      className="dropdown-btn-light"
                      overlay={menu}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <span>
                          {label} <DownOutlined />
                        </span>
                      </a>
                    </Dropdown>
                  </div> */}
                  <div>
                    <Select
                      placeholder={"Select Metal"}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(e) => setMetalId(e)}
                    >
                      {metal?.map((item) => (
                        <Select.Option
                          key={item._id}
                          label={item.categories}
                          value={item._id}
                        >
                          {item.categories}{" "}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <div className="date-picker">
                    <DatePicker.RangePicker
                      value={[
                        filter.start_date
                          ? moment(filter.start_date)
                          : undefined,
                        filter.end_date ? moment(filter.end_date) : undefined,
                      ]}
                      onChange={(value) => {
                        if (value) {
                          setFilter((prev) => ({
                            ...prev,
                            start_date: moment(value[0]).format("YYYY-MM-DD"),
                            end_date: moment(value[1]).format("YYYY-MM-DD"),
                          }));
                        } else {
                          setFilter((prev) => ({
                            ...prev,
                            start_date: undefined,
                            end_date: undefined,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

const TransactionTable = ({ type }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getResources = () => {
    setLoading(true);
    request({
      url:
        type == "all"
          ? `/app/transaction/getTransaction`
          : `/app/transaction/getTransactionByType/${type}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setData(data.data);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, [type]);

  const columns = [
    {
      title: "Transaction Type",
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { orderId }) => {
        return orderId ? orderId?.typeOfTransaction : "-";
      },
    },
    {
      title: "Transaction ID",
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { orderId }) => {
        return orderId ? orderId.transactionId : "-";
      },
    },
    {
      title: "Payment Type",
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { orderId }) => {
        return orderId
          ? orderId?.payment_method === "cashBalance"
            ? lang("Cash Balance")
            : orderId?.payment_method
          : "-";
      },
    },
    {
      title: "Date and Time",
      className: "table",
      dataIndex: "dateOfTransaction",
      key: "dateOfTransaction",
      render: (text) => moment(text).format("MMMM D, YYYY h:mm A"),
    },
    {
      title: "Amount",
      className: "tavble",
      key: "amount",
      dataIndex: "amount",
      render: (_, { amount }) => {
        return amount
          ? parseFloat(amount?.toFixed(2)).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : "-";
      },
    },
    {
      title: "Weight (OZ)",
      className: "tavble",
      key: "quantity",
      dataIndex: "quantity",
      render: (_, { quantity }) => {
        return quantity ? `${quantity?.toFixed(3)} oz` : "-";
      },
    },
  ];

  const onSubmit = (values) => {
    request({
      url: "/v1/webaapi/login",
      method: "POST",
      data: {},
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
        } else {
        }
      },
      onError: (error) => {},
    });
  };

  return (
    <Row gutter={16}>
      <Col span={24} md={24}>
        <div className="date-tital">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="d-flex gap-3"
          >
            {/* <Form.Item className="username-form" label="From" name="email">
              <Space direction="vertical" className="date-picker-box">
                <DatePicker onChange={onChange} />
              </Space>
            </Form.Item>
            <Form.Item className="username-form" label="To" name="email">
              <Space direction="vertical" className="date-picker-box">
                <DatePicker onChange={onChange} />
              </Space>
            </Form.Item> */}
          </Form>
        </div>
        {/* <div className="mt-3">
          <Table
            className="transactions-table table-responsive"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </div> */}
      </Col>
    </Row>
  );
};

export default Transactions;
